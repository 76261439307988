<template>
  <div data-test="label-welcome" class="container">
    Welcome, {{(user || {}).firstName}}
  </div>
</template>
<script>

import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      user: state => (state.auth.customer || {}).user,
    }),
  },
};
</script>
